import React from 'react';

import Router from './Router';
import Header from './components/Header';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends React.Component {
  render() {
    return (
      <div className="app-container page">
        <Header />
        <Router />
        <ToastContainer />
      </div>
    );
  }
}

export default App;
