import React from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import {paypal, tariff} from "../../services/consts";
import {getTariff, saveTariff} from "../../services/tariff";
import classNames from "classnames";

export default class PayPalBtn extends React.Component {

  onSuccess = payment => {
    const {user} = this.props;
    getTariff(user).then(tariffExpire => {
      const date = tariffExpire ? new Date(tariffExpire) : new Date();
      date.setMonth(date.getMonth() + 12);

      saveTariff(user, date);
      window.location.replace("/profile?payment=success");
    });
  };

  onCancel = data => {
    console.log('The payment was cancelled!', data);
    window.location.replace("/profile?payment=cancel");
  };

  onError = err => {
    console.log("Error!", err);
    window.location.replace("/profile?payment=error");
  };

  render = () => {
    return (
      <div>
        <div className={classNames('paypal-btn', 'paypal-btn-1')}>
          <PaypalExpressBtn
            env={paypal.env}
            client={{
              sandbox: paypal.sandboxId,
              production: paypal.id,
            }}
            currency={paypal.currency}
            total={tariff.annual}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onCancel={this.onCancel} />
        </div>
        <div className={classNames('paypal-btn', 'paypal-btn-2')}>
          <PaypalExpressBtn
            env={paypal.env}
            client={{
              sandbox: paypal.sandboxId,
              production: paypal.id,
            }}
            currency={paypal.currency}
            total={tariff.annual}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onCancel={this.onCancel} />
        </div>
      </div>

    )
  }
};