import React from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import {paypal, tariff} from "../../services/consts";
import {createDownloadLink} from "../../services/export";
import classNames from "classnames";

export default class PayPalBtnPerDownload extends React.Component {

  onSuccess = payment => {
    const {user} = this.props;
    createDownloadLink(user).then(() => {
      window.location.replace("/profile?payment=success");
    });
  };

  onCancel = data => {
    console.log('The payment was cancelled!', data);
    window.location.replace("/profile?payment=cancel");
  };

  onError = err => {
    console.log("Error!", err);
    window.location.replace("/profile?payment=error");
  };

  render = () => {
    return (
      <div className={'paypal-btns'}>
        <div className={classNames('paypal-btn-download', 'paypal-download-btn-1')}>
          <PaypalExpressBtn
            env={paypal.env}
            client={{
              sandbox: paypal.sandboxId,
              production: paypal.id,
            }}
            currency={paypal.currency}
            total={tariff.perDownload}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onCancel={this.onCancel} />
        </div>
        <div className={classNames('paypal-btn-download', 'paypal-download-btn-2')}>
          <PaypalExpressBtn
            env={paypal.env}
            client={{
              sandbox: paypal.sandboxId,
              production: paypal.id,
            }}
            currency={paypal.currency}
            total={tariff.perDownload}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onCancel={this.onCancel} />
        </div>
        <div className={classNames('paypal-btn-download', 'paypal-download-btn-3')}>
          <PaypalExpressBtn
            env={paypal.env}
            client={{
              sandbox: paypal.sandboxId,
              production: paypal.id,
            }}
            currency={paypal.currency}
            total={tariff.perDownload}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onCancel={this.onCancel} />
        </div>
      </div>
    )
  }
};