import React from 'react';
import MaterialIcon from '@material/react-material-icon';
import Popup from "reactjs-popup";
import {sounds} from "../../services/consts";

export default class SettingsPopup extends React.Component {
  state = {
    sound: 1,
    frequency: 'Once',
    interval: '01:00',
    error: false,
    cursor: 0,
    selectionStart: null,
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name === 'sound') {
      const audio = parseInt(e.target.value) !== 0 ? new Audio(sounds[e.target.value].url) : null;
      if (audio) {
        audio.play();
        setTimeout(() => {
          audio.muted = true;
        }, 3000)
      }
    }
  };

  onChangeInterval = e => {
    let caret = e.target.selectionStart;
    const element = e.target;
    if (caret === 3) {
      caret = 4;
    }
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });

    let interval = e.target.value.replace(/\D+/g, "");
    if (interval.length > 4) {
      interval = interval.substr(0, interval.length - 1);
    }
    if (interval.length > 2) {
      interval = interval.replace(/(.{2})/, "$1:")
    }
    const error = !interval.match(/^\d\d:[0-5]\d$/) || (interval === '00:00');
    this.setState({interval});
    this.setState({error});
    this.setState({
      cursor: e.target.selectionStart
    });
  };

  render = () => {
    const {
      showModal,
      closeSettingsPopup,
      addIntervalBell,
    } = this.props;

    const {
      sound,
      frequency,
      interval,
      error,
    } = this.state;

    return <Popup
      open={showModal}
      modal
      contentStyle={{
        width: document.documentElement.clientWidth < 800 ? "90%" : "30%"
      }}
      overlayStyle={{
        backgroundColor: "transparent"
      }}
      onClose={closeSettingsPopup}
    >
      <div className="add-popup-content-wrap">
        <div className="add-popup__content">
          <div className="add-popup__item">
            <label className="add-popup__label">Sound</label>
            <div className="mdc-select add-popup__input">
              <select
                className="mdc-select__native-control"
                onChange={this.onChange}
                value={sound}
                name='sound'
              >
                {sounds.map(({title, url}, i) => (
                  url === '' ? '' : <option key={i} value={i}>{title}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="add-popup__item">
            <label className="add-popup__label">Frequency</label>
            <div className="mdc-select add-popup__input">
              <select
                className="mdc-select__native-control"
                onChange={this.onChange}
                value={frequency}
                name='frequency'
              >
                <option value="Once">Once</option>
                <option value="Every">Every</option>
              </select>
            </div>
          </div>
          <div className="add-popup__item">
            <label className="add-popup__label">Interval time</label>
            <input
              className="add-popup__input time-input"
              placeholder="mm:ss"
              value={interval}
              onChange={this.onChangeInterval}
              name='frequency'
            />
          </div>
          <div className="add-popup__error">
            {error ? <span>Please enter valid interval time (mm:ss).</span> : ''}
          </div>
          <div className="add-popup__control-icons">
            <MaterialIcon icon='cancel' onClick={closeSettingsPopup}/>
            <MaterialIcon icon='check_circle' onClick={addIntervalBell({
              sound,
              frequency,
              interval,
              error,
            })}/>
          </div>
        </div>
      </div>
    </Popup>
  }
};