import {GOOGLE_FIT_DATASOURCE, activities} from './consts';

const DAY_QUANTITY = 1000 * 60 * 60 * 24;

export const parseJSON = (string) => {
  try {
    return JSON.parse(string);
  } catch (err) {
    return null;
  }
};

export const stringifyJSON = (obj) => {
  try {
    return JSON.stringify(obj);
  } catch (err) {
    return null;
  }
};

export const ms2ns = (ms) => ms * 1000000; // milliseconds to nanoseconds

export const ns2ms = (ns) => ns / 1000000; // nanoseconds to milliseconds

// milliseconds to minutes
export const ms2m = ms => {
  let seconds = ms / 1000;
  let minutes = Math.floor(seconds / 60);
  return minutes + (seconds - (minutes * 60)) / 100;
};

export const periodString = (from, to) => {
  from = from || (to && to - DAY_QUANTITY * 7) || Date.now() - DAY_QUANTITY * 7;
  to = to || Date.now();
  return `${ms2ns(from)}-${ms2ns(to)}`
};

export const fitDataToD3 = (fitData, period = 1) => {
  let maxValue = 40;
  return {
    data: activities.map(activity => {
      const data = getDataByActivities(activity.id, fitData.point, period);
      data.forEach(point => {
        if (point.y > maxValue) {
          maxValue = point.y;
        }
      });
      return data;
    }),
    maxValue: maxValue
  }
};

export const meditationDataToFit = (sourceId, timerData, endTime, startTime) => (
  {
    "dataSourceId": sourceId,
    "maxEndTimeNs": ms2ns(endTime),
    "minStartTimeNs": ms2ns(startTime || endTime - timerData),
    "point": [
      {
        "dataTypeName": GOOGLE_FIT_DATASOURCE.dataType.name,
        "startTimeNanos": ms2ns(startTime || endTime - timerData),
        "endTimeNanos": ms2ns(endTime),
        "originDataSourceId": "",
        "value": [
          {
            "intVal": "45"
          }
        ]
      }
    ]
  }
);

export const meditationDataToFitSession = (sourceId, timerData, endTime, startTime) => (
  {
    "id": `mypeacepal-${startTime || endTime - timerData}`,
    "name": "Meditation with MyPeacePal",
    "description": startTime || endTime - timerData,
    "startTimeMillis": startTime || endTime - timerData,
    "endTimeMillis": endTime,
    "application": {
      "name": "mypeacepal",
      "version": "1.0"
    },
    "activityType": 45
  }
);

export const yogaDataToFit = (sourceId, timerData, endTime, startTime) => (
  {
    "dataSourceId": sourceId,
    "maxEndTimeNs": ms2ns(endTime),
    "minStartTimeNs": ms2ns(startTime || endTime - timerData),
    "point": [
      {
        "dataTypeName": GOOGLE_FIT_DATASOURCE.dataType.name,
        "startTimeNanos": ms2ns(startTime || endTime - timerData),
        "endTimeNanos": ms2ns(endTime),
        "originDataSourceId": "",
        "value": [
          {
            "intVal": "100"
          }
        ]
      }
    ]
  }
);

export const yogaDataToFitSession = (sourceId, timerData, endTime, startTime) => (
  {
    "id": `mypeacepal-${startTime || endTime - timerData}`,
    "name": "Yoga with MyPeacePal",
    "description": startTime || endTime - timerData,
    "startTimeMillis": startTime || endTime - timerData,
    "endTimeMillis": endTime,
    "application": {
      "name": "mypeacepal",
      "version": "1.0"
    },
    "activityType": 100
  }
);

export const extractFromProvidersData = (user, key) => (
  user && (
    (
      user.providerData &&
      user.providerData.reduce((prev, el) => prev || el[key], null)
    ) || (
      user.additionalUserInfo &&
      user.additionalUserInfo.profile &&
      user.additionalUserInfo.profile[key]
    )
  )
);


export const formatDate = date => {
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

  return `${date.getFullYear()}/${month}/${day}`;
};

const getDataByActivities = (activity, points, period) => {
  let minPart = Date.now();
  let maxPart = 0;
  let loopLimit = 1000;
  const q = (period > 31 ? 30 : 1) * DAY_QUANTITY;

  const mergedPoints = points.reduce((prev, el) => {
    if (el.value[0].intVal !== activity) {
      return prev;
    }
    const part = Math.floor(ns2ms(el.endTimeNanos) / q);
    minPart = Math.min(minPart, part);
    maxPart = Math.max(maxPart, part);
    prev[part] = (prev[part] || 0) + +(ns2ms(el.endTimeNanos - el.startTimeNanos) / 1000 / 60).toFixed(2);
    return prev;
  }, {});

  // fill empty parts
  while (minPart < maxPart && loopLimit) {
    --loopLimit;
    const temp = --maxPart;
    if (!mergedPoints[temp]) {
      mergedPoints[temp] = 0;
    }
  }
  const result = Object.keys(mergedPoints).map(key => {
    return {
      x: new Date(key*q).toISOString(),
      y: mergedPoints[key]
    }
  });

  return result.length > 1 ? result.slice(-period) : [...result, {
    x: new Date(Date.now() - 1000 * 60 * 60 * 24).toISOString(),
    y: 0
  }];
};

export const getInitialTimerValue = type => {
  const activity = parseInt(localStorage.getItem(`activity`));

  if (activity === 45) {
    return localStorage.getItem(`${type}_initial_value_meditation`)
  }
  if (activity === 100) {
    return localStorage.getItem(`${type}_initial_value_yoga`)
  }
};

export const setInitialTimerValue = (type, value) => {
  const activity = parseInt(localStorage.getItem(`activity`));

  if (activity === 45) {
    return localStorage.setItem(`${type}_initial_value_meditation`, value);
  }
  if (activity === 100) {
    return localStorage.setItem(`${type}_initial_value_yoga`, value);
  }
};

export const getBellStart = () => {
  const activity = parseInt(localStorage.getItem(`activity`));

  if (activity === 45) {
    return parseInt(localStorage.getItem(`bellStart_meditation`));
  }
  if (activity === 100) {
    return parseInt(localStorage.getItem(`bellStart_yoga`));
  }
};

export const setBellStart = value => {
  const activity = parseInt(localStorage.getItem(`activity`));

  if (activity === 45) {
    return localStorage.setItem(`bellStart_meditation`, value);
  }
  if (activity === 100) {
    return localStorage.setItem(`bellStart_yoga`, value);
  }
};


export const getBellFinish = () => {
  const activity = parseInt(localStorage.getItem(`activity`));

  if (activity === 45) {
    return parseInt(localStorage.getItem(`bellFinish_meditation`));
  }
  if (activity === 100) {
    return parseInt(localStorage.getItem(`bellFinish_yoga`));
  }
};

export const setBellFinish = value => {
  const activity = parseInt(localStorage.getItem(`activity`));

  if (activity === 45) {
    return localStorage.setItem(`bellFinish_meditation`, value);
  }
  if (activity === 100) {
    return localStorage.setItem(`bellFinish_yoga`, value);
  }
};

export const getClock = () => {
  const activity = parseInt(localStorage.getItem(`activity`));

  if (activity === 45) {
    return parseInt(localStorage.getItem(`clock_meditation`));
  }
  if (activity === 100) {
    return parseInt(localStorage.getItem(`clock_yoga`));
  }
};

export const setClock = value => {
  const activity = parseInt(localStorage.getItem(`activity`));

  if (activity === 45) {
    return localStorage.setItem(`clock_meditation`, value);
  }
  if (activity === 100) {
    return localStorage.setItem(`clock_yoga`, value);
  }
};

export const getIntervals = () => {
  const activity = parseInt(localStorage.getItem(`activity`));

  if (activity === 45) {
    return localStorage.getItem(`intervals_meditation`);
  }
  if (activity === 100) {
    return localStorage.getItem(`intervals_yoga`);
  }
};

export const setIntervals = value => {
  const activity = parseInt(localStorage.getItem(`activity`));

  if (activity === 45) {
    return localStorage.setItem(`intervals_meditation`, value);
  }
  if (activity === 100) {
    return localStorage.setItem(`intervals_yoga`, value);
  }
};