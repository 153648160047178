import React from 'react';

import TimerPage from './TimerPage';
import JournalPage from './JournalPage';
import ChartPage from './ChartPage';
import SwipeNavigation from '../components/SwipeNavigation';

export default class MainPage extends React.Component {

  constructor() {
    super();

    this.state = {
      activeView: 0
    };

    this.changeView = this.changeView.bind(this);
  }

  changeView(i) {
    this.setState({
      activeView: i
    });
  }

  render() {
    return (
      <div className="main-page page">
        <SwipeNavigation>
          <TimerPage />
          <JournalPage />
          <ChartPage />
        </SwipeNavigation>
      </div>
    )
  }
};
