import React, {Component} from 'react';
import Payment from "../components/Payment/Payment";
import {getUser} from "../services/auth";
import Auth from "../components/Auth";
import classNames from 'classnames';
import {getTariff} from "../services/tariff";
import MaterialIcon from '@material/react-material-icon';
import {exportToExcel, getDownloadLink} from "../services/export";
import queryString from 'query-string';
import Popup from "reactjs-popup";
import {tariff} from "../services/consts";
import PayPalBtnPerDownload from "../components/Payment/PayPalBtnPerDownload";

export default class ProfilePage extends Component {
  state = {
    user: null,
    loaded: false,
    expireAt: null,
    modal: false,
    status: null,
    downloadLink: null,
  };

  componentWillMount = () => {
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.payment) {
      this.showModal(parsed.payment);
    }
    getUser().then(user => {
      this.setState({
        loaded: true,
        user: user ? user : null,
      });
      getTariff(user).then(tariffExpire => {
        if (tariffExpire) {
          this.setState({
            expireAt: new Date(tariffExpire),
          });
        }
      });
      getDownloadLink(user).then(expireAt => {
        if (expireAt) {
          this.setState({
            downloadLink: new Date(expireAt),
          });
        }
      });
    });
  };

  isMember = () => {
    const {expireAt} = this.state;
    return expireAt !== null && expireAt.getTime() >= Date.now();
  };

  isValidDownloadLink = () => {
    const {downloadLink} = this.state;
    return downloadLink !== null && downloadLink.getTime() >= Date.now();
  };

  getFormattedDate = () => {
    const {expireAt} = this.state;
    return `${expireAt.toLocaleString("en-US", {month: 'long'})} ${expireAt.getDate()}, ${expireAt.getFullYear()}`;
  };

  getFormattedDateTime = () => {
    const {downloadLink} = this.state;
    return `${downloadLink.getHours()}:${downloadLink.getMinutes()} ${downloadLink.toLocaleString("en-US", {month: 'long'})} ${downloadLink.getDate()}, ${downloadLink.getFullYear()}`;
  };

  showModal = status => {
    this.setState({
      modal: true,
      status,
    });
  };

  closeModal = () => {
    this.setState({
      modal: false,
    });
  };

  render() {
    const {user, loaded, modal, status} = this.state;

    return (
      <div className={classNames('content-page', "page", "profile-page")}>
        <Popup
          open={modal}
          modal
          contentStyle={{
            width: document.documentElement.clientWidth < 800 ? "90%" : "40%",
          }}
          overlayStyle={{
            backgroundColor: "rgba(255,255,255,0.5)",
          }}
          onClose={this.closeModal}
        >
          <div className={'popup-container'}>
            <div className={'payment-status'}>
              {status === 'success' ? 'Payment was successful' : null}
              {status === 'cancel' ? 'Payment was canceled' : null}
              {status === 'error' ? 'An error occurred' : null}
            </div>
          </div>
        </Popup>

        <article className="post">
          <h1>My Profile</h1>

          <div className='profile-info'>
            {!loaded ? null : (user ? (
              <div>
                <p className={'bold'}>Hello {user.displayName}!</p>
                {this.isMember() ? (
                  <div>
                    <p>Your MyPeacePal Plus Membership is set to expire on</p>
                    <p>{this.getFormattedDate()}</p>
                  </div>
                ) : (
                  <div>
                    <p>You currently not a member of MyPeacePal Plus</p>
                  </div>
                )}
                <div className={'hr'}/>
                <p className={'bold'}>Plus Membership Benefits</p>
                <p>Download Journal Entries and Meditations Sessions</p>
                {this.isMember() ? (
                  <div>
                    <p className={'bold'}>Extend MyPeacePal Plus</p>
                    <Payment user={user} btnText={'Extend'}/>
                  </div>
                ) : (
                  <div>
                    <p className={'bold'}>Upgrade to MyPeacePal Plus</p>
                    <Payment user={user} btnText={'Upgrade'}/>
                  </div>
                )}
                <div className={'hr'}/>
                {this.isMember() ? (
                  <div className={'download'} onClick={exportToExcel(user)}>
                    <MaterialIcon icon={'cloud_download'}/>
                    <span>Download</span>
                  </div>
                ) : (
                  <div>
                    {this.isValidDownloadLink() ? (
                      <div>
                        <p className={'bold'}>Pay to download</p>
                        <p>Your download link expires at {this.getFormattedDateTime()}.</p>
                        <div className={'download'} onClick={exportToExcel(user)}>
                          <MaterialIcon icon={'cloud_download'}/>
                          <span>Download</span>
                        </div>
                      </div>
                    ) : (
                      <div className={'flex'}>
                        <div>
                          <p className={'bold'}>Pay to download</p>
                          <p className={'low-height'}>Get an Excel file with all sessions for just
                            ${tariff.perDownload}.</p>
                          <p className={'low-height'}>The link will be valid for 2 days after purchase.</p>
                        </div>
                        <div className={'relative'}>
                          <div className={classNames('btn', 'btn-download')}>
                            Buy a
                            <br/>
                            download
                          </div>
                          <PayPalBtnPerDownload user={user}/>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className={'centered'}>
                <p>Please login to see your account status and features: session download/export, etc.</p>
                <div>
                  <Auth/>
                </div>
              </div>
            ))}
          </div>
        </article>
      </div>
    )
  }
};
