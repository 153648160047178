import React from 'react';

export default props => {
  return (
    <div className="content-page page">
      <article className="post">
        <h1>FAQs</h1>

        <h3>How does MyPeacePal work with Google Fit?</h3>
        <p>MyPeacePal uses your Google account to store your data. The app syncs your sessions automatically with Google Fit. You can unlink MyPeacePal from Google Fit at any time and delete associated data.</p>

        <h3>How is the data saved?</h3>
        <p>When you are logged in, the data is synced with the cloud automatically. If you are not logged in, the data is stored locally and synced after the next logged in session.</p>

        <h3>Can I save the data manually?</h3>
        <p>Yes. If you need to interrupt your session early, press the Pause icon and then the Cloud icon and follow the prompts.</p>
        
        <h3>How can I delete my session data?</h3>
        <p>Simply log into your Google Fit account and follow it's instructions to delete your fitness history.</p>

        <h3>Where can I find your Privacy Policy and Terms of use?</h3>
        <p>Click on the following links for the application <a href="https://mypeacepal.com/policies">Privacy Policy</a> and <a href="https://mypeacepal.com/policies">Terms of Service</a>.</p>
        </article>
    </div>
  )
};
