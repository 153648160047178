import {database} from "./firebase";

export const getTariff = (user) => {
  if (user && user.uid) {
    return database
      .ref(`users/${user.uid}/tariffExpire`)
      .once('value')
      .then(snapshot => snapshot.val());
  } else {
    return new Promise(resolve => resolve());
  }
};

export const saveTariff = (user, expiredAt) => {
  if (user && user.uid) {
    const updates = {};
    updates[`users/${user.uid}/tariffExpire`] = expiredAt;

    return database.ref().update(updates);
  }
};

