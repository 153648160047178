import React from 'react';
import {tariff} from "../../services/consts";
import classNames from 'classnames';
import PayPalBtn from "./PayPalBtn";

export default class Payment extends React.Component {

  render = () => {
    const {user, btnText} = this.props;

    return (
      <div className='payment-container'>
        <div>
          <div>
            <p className={'bold'}>{`Billed as $${tariff.annual} / year`}</p>
          </div>
        </div>
        <div>
          <div className={classNames('btn', 'paypal-btn-container')}>
            {btnText}
            <div className={'paypal-btn'}>
              <PayPalBtn user={user}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
};
