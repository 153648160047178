import React from "react";
import classnames from "classnames";
import SwipeableViews from "react-swipeable-views";
import MaterialIcon from '@material/react-material-icon';

const tabs = [
  {
    icon: "av_timer",
    text: "TIMER"
  },
  {
    icon: "assignment",
    text: "JOURNAL"
  },
  {
    icon: "show_chart",
    text: "CHART"
  }
]

export default class Navigation extends React.Component {
  constructor() {
    super();

    this.state = {
      index: 0
    };

    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
  }

  handleChangeIndex(newIndex) {
    this.setState({
      index: newIndex
    });
  }

  generateTabs(tagsArray) {
    return tagsArray.map((el, i) => {
      return (
        <div
          key={i}
          className={classnames("navigation-tab", {
            "active-tab": this.state.index === i
          })}
          onClick={() => {
            this.handleChangeIndex(i);
          }}
        >
          <MaterialIcon
            className="tab-icon"
            icon={el.icon}
          />
          <span className="tab-text">
            {el.text}
          </span>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="navigation">
        <SwipeableViews
          resistance
          className="tab-content"
          enableMouseEvents={true}
          index={this.state.index}
          onChangeIndex={this.handleChangeIndex}
        >
          {this.props.children}
        </SwipeableViews>
        <div className="navigation-bar">
          {this.generateTabs(tabs)}
        </div>
      </div>
    );
  }
}