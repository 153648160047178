import React, {Component} from 'react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import MaterialIcon from '@material/react-material-icon';
import Payment from "../components/Payment/Payment";
import {getUser} from "../services/auth";
import Auth from "../components/Auth";
import classNames from 'classnames';

export default class SupportPage extends Component {
  state = {
    expanded: document.documentElement.clientWidth > 800 ? 'panel1' : '',
    user: null,
    loaded: false,
  };

  componentWillMount = () => {
    getUser().then(user => {
      this.setState({
        loaded: true,
        user: user ? user : null,
      });
    });
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const {expanded, user, loaded} = this.state;

    return (
      <div className={classNames('content-page', "page", "support-page")}>
        <article className="post">
          <h1>Support Project</h1>

          <div className='support-info'>
            <h3>MyPeacePal is free.</h3>
            <p>We need your support to continue improving MyPeacePal.</p>
          </div>
        </article>
        <div className='support-panels'>
          <ExpansionPanel
            square
            expanded={expanded === 'panel1'}
            onChange={this.handleChange('panel1')}
            className={'support-panel'}
          >
            <ExpansionPanelSummary
              expandIcon={<MaterialIcon icon="expand_more"/>}
              classes={{
                root: 'support-panel__summary',
                expanded: 'expanded',
                content: 'panel-header',
                expandIcon: 'expandIcon',
              }}
            >
              Upgrade to MyPeacePal Plus
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{root: 'support-panel__details'}}>
              <div>
                <List>
                  <ListItem
                    disableGutters
                    classes={{
                      root: 'support-panel__list-item'
                    }}
                  >
                    <ListItemIcon
                      classes={{
                        root: 'support-panel__icon'
                      }}
                    >
                      <MaterialIcon icon="done"/>
                    </ListItemIcon>
                    <ListItemText
                      primary="Everything included in our Free plan"
                      classes={{
                        root: 'support-panel__list-text'
                      }}
                    />
                  </ListItem>
                  <ListItem
                    disableGutters
                    classes={{
                      root: 'support-panel__list-item'
                    }}
                  >
                    <ListItemIcon
                      classes={{
                        root: 'support-panel__icon'
                      }}
                    >
                      <MaterialIcon icon="add"/>
                    </ListItemIcon>
                    <ListItemText
                      primary="Export journal entries to Excel"
                      classes={{
                        root: 'support-panel__list-text'
                      }}
                    />
                  </ListItem>
                  <ListItem
                    disableGutters
                    classes={{
                      root: 'support-panel__list-item'
                    }}
                  >
                    <ListItemIcon
                      classes={{
                        root: 'support-panel__icon'
                      }}
                    >
                      <MaterialIcon icon="add"/>
                    </ListItemIcon>
                    <ListItemText
                      primary="Priority customer support"
                      classes={{
                        root: 'support-panel__list-text'
                      }}
                    />
                  </ListItem>
                </List>
                {!loaded ? null : (user ? <Payment user={user} btnText={'Upgrade'}/> : <Auth/>)}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            square
            expanded={expanded === 'panel2'}
            onChange={this.handleChange('panel2')}
            className={'support-panel'}
          >
            <ExpansionPanelSummary
              expandIcon={<MaterialIcon icon="expand_more"/>}
              classes={{
                root: 'support-panel__summary',
                expanded: 'expanded',
                content: 'panel-header',
                expandIcon: 'expandIcon',
              }}
            >
              Support Project
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{root: 'support-panel__details'}}>
              <div>
                <h3>You can help keep the app free and support its development. </h3>
                <p>Simply shop at <a href="https://mypeacepal.com/" target="_blank" rel="noopener noreferrer">MyPeacePal
                  Shop</a>.
                  The shop is a curated collection of popular products for meditation practices,
                  stress management and relaxation. Use affiliate links to shop at Amazon. Every bit counts.
                  <br/><br/>
                  Thank you!
                  <br/>
                  The MyPeacePal Team
                </p>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </div>
    )
  }
};
