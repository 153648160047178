import React from 'react';

import {getUser} from '../services/auth';
import Auth from "../components/Auth";

export default class FeedbackPage extends React.Component {
  state = {
    user: null,
    loaded: false,
  };

  componentWillMount = () => {
    getUser().then(user => {
      this.setState({
        loaded: true,
        user: user ? user : null,
      });
    });
  };

  render() {
    const {user, loaded} = this.state;
    if (!loaded) return null;
    return (
      <div className="content-page page">
        {
          loaded && user ? (
            <article className="post">
              <h1>Feedback</h1>
              <p>Please send an email with your issue or ideas to
                <a href="mailto:appfeedback@mypeacepal.com">appfeedback@mypeacepal.com</a>
              </p>
              <p>We will use the information you give us to help address technical issues and improve our services,
                subject to our <a href="https://mypeacepal.com/policies">Privacy Policy</a> and
                <a href="https://mypeacepal.com/policies">Terms of Service</a>
              </p>
            </article>
          ) : (
            <div className="login-overlay flex-center without-bg">
              <Auth/>
              <p>Sign in helps prevent spam</p>
            </div>
          )
        }
      </div>
    )
  }
};
