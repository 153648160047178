import {database} from "./firebase";
import {getUser} from "./auth";
import {formatDate} from "./utils";

export const saveMeditationToFirebase = async data => {
  const user = await getUser();
  if (user && user.uid) {
    const key = database.ref().child(`users/${user.uid}/meditations`).push().key;

    const updates = {};
    updates[`users/${user.uid}/meditations/${key}`] = data;

    return database.ref().update(updates);
  }
};

export const getMeditations = async (user, groupByDate = false) => {
  if (user && user.uid) {
    return database
      .ref(`users/${user.uid}/meditations`)
      .orderByChild('date')
      .once('value')
      .then(snapshot => {
        let meditations = [];
        snapshot.forEach(meditation => {
          meditations.push({
            date: new Date(meditation.val().date),
            time: meditation.val().value,
          });
        });
        if (groupByDate) {
          const meditationsByDate = [];
          const dates = [];
          meditations.forEach(meditation => {
            let index = dates.indexOf(formatDate(meditation.date));
            if (index !== -1) {
              let element = meditationsByDate.find(item => formatDate(item.date) === dates[index]);
              meditationsByDate[meditationsByDate.indexOf(element)].time += meditation.time;
            } else {
              dates.push(formatDate(meditation.date));
              meditationsByDate.push(meditation)
            }
          });
          return meditationsByDate;
        }
        return meditations;
      });
  } else {
    return [];
  }
};
