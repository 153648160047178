import React from 'react';
import classNames from 'classnames';
import {AreaChart} from 'react-easy-chart';

import ToolTip from './Tooltip';

const DAY = 1000 * 60 * 60 * 24;

export default class Chart extends React.Component {
  constructor() {
    super();

    this.state = {
      showToolTip: false,
      tooltipTop: null,
      tooltipLeft: null,
      tooltipValueX: 0,
      tooltipValueY: 0,
      componentWidth: 500,
      componentHeight: 500
    };

    this.handleResize = this.handleResize.bind(this);
    this.mouseOverHandler = this.mouseOverHandler.bind(this);
    this.mouseOutHandler = this.mouseOutHandler.bind(this);
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.setState({
      componentWidth: this.chartWrapEl.clientWidth,
      componentHeight: this.chartWrapEl.clientHeight
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      componentWidth: this.chartWrapEl.clientWidth,
      componentHeight: this.chartWrapEl.clientHeight
    });
  }

  mouseOverHandler(d, e) {
    this.setState({
      showToolTip: true,
      tooltipTop: `${e.screenY - 10}px`,
      tooltipLeft: `${e.screenX + 10}px`,
      tooltipValueY: d.y,
      tooltipValueX: d.x
    });
  }

  mouseMoveHandler(e) {
    if (this.state.showToolTip) {
      this.setState({
        tooltipTop: `${e.y - 10}px`,
        tooltipLeft: `${e.x + 10}px`
      });
    }
  }

  mouseOutHandler() {
    this.setState({
      showToolTip: false
    });
  }

  render() {
    const {componentWidth, componentHeight, tooltipValueX, tooltipValueY, showToolTip} = this.state;
    const {data, maxValue, period, tickFormat} = this.props;
    return (
      <div ref={el => this.chartWrapEl = el} className="chart-block">
        <ToolTip
          className={classNames({
            "hidden": !showToolTip
          })}
        >
          {tooltipValueX} {tooltipValueY}
        </ToolTip>
        <AreaChart
          axes
          dataPoints
          datePattern='%Y-%m-%dT%H:%M:%S.%LZ'
          tickTimeDisplayFormat={tickFormat || "%a"}
          xDomainRange={[new Date(Date.now() - DAY * period).toISOString(), new Date().toISOString()]}
          yDomainRange={[0, maxValue + 20]}
          margin={{top: 20, right: 20, bottom: 55, left: 55}}
          xType={'time'}
          xTicks={period <= 10 ? period : 10}
          yTicks={5}
          axisLabels={{x: 'Date', y: 'Min'}}
          lineColors={['inherit']}
          areaColors={['white', 'orange']}
          mouseOverHandler={this.mouseOverHandler}
          mouseOutHandler={this.mouseOutHandler}
          mouseMoveHandler={this.mouseMoveHandler}
          width={componentWidth}
          height={componentHeight * 0.9}
          // interpolate={'cardinal'}
          data={data}
        />
      </div>
    )
  }
};