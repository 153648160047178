import {postFitnesDataSet, postFitnesSession} from './api';
import {
  meditationDataToFit,
  periodString,
  parseJSON,
  stringifyJSON,
  meditationDataToFitSession,
  yogaDataToFit, yogaDataToFitSession, getInitialTimerValue
} from './utils';
import {timeStringToSeconds} from './timerEngine';
import {saveMeditationToFirebase} from "./meditation";
import {saveYogaToFirebase} from "./yoga";


export const saveResultsToGoogle = lastCount => {
  const activity = parseInt(localStorage.getItem(`activity`));
  if (activity === 45) {
    saveMeditationToGoogle(lastCount);
  }
  if (activity === 100) {
    saveYogaToGoogle(lastCount);
  }
};

export const saveResultsToCache = lastCount => {
  const activity = parseInt(localStorage.getItem(`activity`));
  if (activity === 45) {
    saveMeditationToCache(lastCount);
  }
  if (activity === 100) {
    saveYogaToCache(lastCount);
  }
};

const saveMeditationToCache = lastCount => {
  const cachedData = parseJSON(localStorage.getItem(`cached_meditations`)) || [];
  const mergedData = [
    ...cachedData,
    {
      value: getMs(lastCount),
      timeString: periodString(),
      date: Date.now()
    }
  ];
  localStorage.setItem(`cached_meditations`, stringifyJSON(mergedData));
};

const saveYogaToCache = lastCount => {
  const cachedData = parseJSON(localStorage.getItem(`cached_yoga`)) || [];
  const mergedData = [
    ...cachedData,
    {
      value: getMs(lastCount),
      timeString: periodString(),
      date: Date.now()
    }
  ];
  localStorage.setItem(`cached_yoga`, stringifyJSON(mergedData));
};

const saveMeditationToGoogle = lastCount => {
  const streamId = localStorage.getItem("data_stream_id");
  const cachedData = parseJSON(localStorage.getItem(`cached_meditations`)) || [];
  const mergedData = [
    ...cachedData,
    {
      value: getMs(lastCount),
      timeString: periodString(),
      date: Date.now()
    }
  ];
  mergedData.forEach(d => {
    // Send meditation dataSet to GoogleFit
    postFitnesDataSet(streamId, d.timeString, stringifyJSON(meditationDataToFit(streamId, d.value, d.date))).then(() => {
      // Send meditation session to GoogleFit
      postFitnesSession(`mypeacepal-${d.date - d.value}`, stringifyJSON(meditationDataToFitSession(streamId, d.value, d.date)));
      // Send meditation dataSet to Firebase
      saveMeditationToFirebase({value: d.value, timeString: d.timeString, date: d.date});
    });
  });
  localStorage.removeItem(`cached_meditations`);
  saveCachedYoga();
};

const saveYogaToGoogle = lastCount => {
  const streamId = localStorage.getItem("data_stream_id");
  const cachedData = parseJSON(localStorage.getItem(`cached_yoga`)) || [];
  const mergedData = [
    ...cachedData,
    {
      value: getMs(lastCount),
      timeString: periodString(),
      date: Date.now()
    }
  ];
  mergedData.forEach(d => {
    // Send yoga dataSet to GoogleFit
    postFitnesDataSet(streamId, d.timeString, stringifyJSON(yogaDataToFit(streamId, d.value, d.date))).then(() => {
      // Send yoga session to GoogleFit
      postFitnesSession(`mypeacepal-${d.date - d.value}`, stringifyJSON(yogaDataToFitSession(streamId, d.value, d.date)));
      // Send yoga dataSet to Firebase
      saveYogaToFirebase({value: d.value, timeString: d.timeString, date: d.date});
    });
  });
  localStorage.removeItem(`cached_yoga`);
  saveCachedMeditations();
};

const saveCachedYoga = () => {
  const streamId = localStorage.getItem("data_stream_id");
  const data = parseJSON(localStorage.getItem(`cached_yoga`)) || [];
  if (!data.length) {
    return null;
  }
  data.forEach(d => {
    // Send cached yoga dataSet to GoogleFit
    postFitnesDataSet(streamId, d.timeString, stringifyJSON(yogaDataToFit(streamId, d.value, d.date))).then(() => {
      // Send cached yoga session to GoogleFit
      postFitnesSession(`mypeacepal-${d.date - d.value}`, stringifyJSON(yogaDataToFitSession(streamId, d.value, d.date)));
      // Send cached yoga dataSet to Firebase
      saveYogaToFirebase({value: d.value, timeString: d.timeString, date: d.date});
    });
  });
  localStorage.removeItem(`cached_yoga`);
};

const saveCachedMeditations = () => {
  const streamId = localStorage.getItem("data_stream_id");
  const data = parseJSON(localStorage.getItem(`cached_meditations`)) || [];
  if (!data.length) {
    return null;
  }
  data.forEach(d => {
    // Send cached meditation dataSet to GoogleFit
    postFitnesDataSet(streamId, d.timeString, stringifyJSON(meditationDataToFit(streamId, d.value, d.date))).then(() => {
      // Send cached meditation session to GoogleFit
      postFitnesSession(`mypeacepal-${d.date - d.value}`, stringifyJSON(meditationDataToFitSession(streamId, d.value, d.date)));
      // Send cached meditation dataSet to Firebase
      saveMeditationToFirebase({value: d.value, timeString: d.timeString, date: d.date});
    });
  });
  localStorage.removeItem(`cached_meditations`);
};

const getMs = lastCount => {
  const clock = parseInt(localStorage.getItem(`clock`));
  if (clock === 1) {
    return timeStringToSeconds(lastCount) * 1000;
  }
  const initTime = getInitialTimerValue('main');
  return lastCount
    ? (timeStringToSeconds(initTime) - timeStringToSeconds(lastCount)) * 1000
    : timeStringToSeconds(initTime) * 1000;
};
