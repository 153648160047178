import React from 'react';
import MaterialIcon from '@material/react-material-icon';
import {sounds, clocks} from "../../services/consts";
import SettingsPopup from "./SettingsPopup";

export default class Settings extends React.Component {
  state = {
    showModal: false,
  };

  openSettingsPopup = () => {
    this.setState({
      showModal: true,
    })
  };

  closeSettingsPopup = () => {
    this.setState({
      showModal: false,
    })
  };

  addIntervalBell = data => () => {
    if (data.error) {
      return;
    }
    this.props.onAddIntervalBell(data);
    this.setState({
      showModal: false,
    })
  };

  render = () => {
    const {
      intervals,
      clock,
      bellStart,
      bellFinish,
      onChangeBellSelect,
      onChangeSelect,
      closeSettings,
      applySettings,
      removeIntervalBell,
      activity,
    } = this.props;

    return <div className="settings-timer">
      <SettingsPopup
        showModal={this.state.showModal}
        closeSettingsPopup={this.closeSettingsPopup}
        addIntervalBell={this.addIntervalBell}
      />
      <div className="settings-timer__header">
        <MaterialIcon icon='arrow_back' onClick={closeSettings}/>
        <span>{activity === 45 ? 'Meditation' : 'Yoga'} Timer</span>
        <MaterialIcon icon='done' onClick={applySettings}/>
      </div>
      <div className="settings-timer__content">
        <div className="settings-timer__title">
          <MaterialIcon icon='settings'/>
          <span>Settings</span>
        </div>
        <div className="settings-config">
          <div className="settings-config__item">
            <label className="settings-config__label">Clock</label>
            <div className="mdc-select settings-config__input">
              <select
                className="mdc-select__native-control"
                value={clock}
                onChange={onChangeSelect}
                name='clock'
              >
                {clocks.map((title, id) => (
                  <option key={id} value={id}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="settings-config__item">
            <label className="settings-config__label">Starting bell</label>
            <div className="mdc-select settings-config__input">
              <select
                className="mdc-select__native-control"
                value={bellStart}
                name='bellStart'
                onChange={onChangeBellSelect}
              >
                {sounds.map(({title, url}, i) => (
                  <option key={i} value={i}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="settings-config__item">
            <label className="settings-config__label">Interval bell</label>
            <div className="settings-config__input add-interval" onClick={this.openSettingsPopup}>
              <MaterialIcon icon='add_circle_outline'/>
              <span>Add</span>
            </div>
            <ul className="intervals">
              {intervals.map(({sound, frequency, interval}, i) => (
                <div className="intervals__bell" key={i}>
                  <span className="intervals__name">{`${i + 1}. ${sounds[sound].title}`}</span>
                  <span className="intervals__frequency">{frequency}</span>
                  <span className="intervals__time">{interval} min</span>
                  <MaterialIcon icon='delete_outline' onClick={removeIntervalBell(i)}/>
                </div>
              ))}
            </ul>
          </div>
          <div className="settings-config__item">
            <label className="settings-config__label">Ending bell</label>
            <div className="mdc-select settings-config__input">
              <select
                className="mdc-select__native-control"
                value={bellFinish}
                name='bellFinish'
                onChange={onChangeBellSelect}
              >
                {sounds.map(({title, url}, i) => (
                  <option key={i} value={i}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
};