import React from 'react';
import MaterialIcon from '@material/react-material-icon';
import classNames from 'classnames';
import BgTimerHelper from 'bg-timer-helper';
import Popup from "reactjs-popup";

import TimerDigits from '../components/Timer/TimerDigits';
import NoSleep from '../services/noSleep';
import {saveResultsToGoogle, saveResultsToCache} from '../services/fitness';
import {saveSettings, getSettings, saveActivity} from '../services/settings';
import {getUser} from '../services/auth';
import Settings from "../components/Settings/Settings";
import {sounds, activities} from "../services/consts";
import {timeStringToSeconds} from "../services/timerEngine";
import StopwatchDigits from "../components/Timer/StopwatchDigits";
import {
  getBellFinish,
  getBellStart,
  getClock,
  getInitialTimerValue, getIntervals,
  setBellFinish,
  setBellStart,
  setClock, setIntervals
} from "../services/utils";

export default class Timer extends React.Component {
  constructor() {
    super();
    this.state = {
      prepareTimerStatus: "stopped",
      mainTimerStatus: "stopped",
      showModal: false,
      showSettings: false,
      showSettingsModal: false,
      bellStart: 1,
      bellFinish: 1,
      intervals: [],
      prepareTimer: 0,
      mainTimer: 0,
      activity: 45,
      clock: 0,
      switcherOpen: false,
    };

    this.currentTimeString = "";
    this.noSleep = new NoSleep();
    this.bgTimerHelper = new BgTimerHelper();

    this.startTimer = this.startTimer.bind(this);
    this.pauseTimer = this.pauseTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    this.onPrepareTimerFinished = this.onPrepareTimerFinished.bind(this);
    this.onMainTimerFinished = this.onMainTimerFinished.bind(this);
    this.stopAndSaveMeditation = this.stopAndSaveMeditation.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
    this.onTimeUpdated = this.onTimeUpdated.bind(this);
    this.confirmStop = this.confirmStop.bind(this);
    this.confirmStopAndSync = this.confirmStopAndSync.bind(this);
    this.openSettings = this.openSettings.bind(this);
    this.closeSettings = this.closeSettings.bind(this);
    this.applySettings = this.applySettings.bind(this);
    this.onAddIntervalBell = this.onAddIntervalBell.bind(this);
    this.setTime = this.setTime.bind(this);
    this.resumeTimer = this.resumeTimer.bind(this);
  }

  componentWillUnmount() {
    this.noSleep.disable();
    this.bgTimerHelper.disable();
  }

  componentDidMount() {
    this.loadSettings().then(() => {
      this.setInitialSettings();
    });
  }

  playBell = (sound, mute = null) => {
    if (parseInt(sound) === 0) return;
    const audio = new Audio(sounds[parseInt(sound)].url);
    if (!audio) return;
    audio.play();
    if (!mute) return;
    setTimeout(() => {
      audio.muted = true;
    }, mute);
  };

  loadSettings = async () => {
    const user = await getUser();
    if (user) {
      const settings = await getSettings(user);
      if (!settings) {
        return;
      }
      if (settings.meditation && settings.meditation.bellStart) {
        localStorage.setItem(`bellStart_meditation`, settings.meditation.bellStart);
      }
      if (settings.yoga && settings.yoga.bellStart) {
        localStorage.setItem(`bellStart_yoga`, settings.yoga.bellStart);
      }
      if (settings.meditation && settings.meditation.bellFinish) {
        localStorage.setItem(`bellFinish_meditation`, settings.meditation.bellFinish);
      }
      if (settings.yoga && settings.yoga.bellFinish) {
        localStorage.setItem(`bellFinish_yoga`, settings.yoga.bellFinish);
      }
      if (settings.meditation && settings.meditation.mainTimerInitValue) {
        localStorage.setItem(`main_initial_value_meditation`, settings.meditation.mainTimerInitValue);
      }
      if (settings.meditation && settings.meditation.prepareTimerInitValue) {
        localStorage.setItem(`prepare_initial_value_meditation`, settings.meditation.prepareTimerInitValue);
      }
      if (settings.yoga && settings.yoga.mainTimerInitValue) {
        localStorage.setItem(`main_initial_value_yoga`, settings.yoga.mainTimerInitValue);
      }
      if (settings.yoga && settings.yoga.prepareTimerInitValue) {
        localStorage.setItem(`prepare_initial_value_yoga`, settings.yoga.prepareTimerInitValue);
      }
      if (settings.meditation && settings.meditation.intervals) {
        localStorage.setItem(`intervals_meditation`, JSON.stringify(settings.meditation.intervals));
      }
      if (settings.yoga && settings.yoga.intervals) {
        localStorage.setItem(`intervals_yoga`, JSON.stringify(settings.yoga.intervals));
      }
      if (settings.activity) {
        localStorage.setItem(`activity`, settings.activity);
      }
      if (settings.meditation && settings.meditation.clock) {
        localStorage.setItem(`clock_meditation`, settings.meditation.clock);
      }
      if (settings.yoga && settings.yoga.clock) {
        localStorage.setItem(`clock_yoga`, settings.yoga.clock);
      }
    }

  };

  setInitialSettings = () => {
    let activity = parseInt(localStorage.getItem(`activity`));
    if (!activity) {
      activity = 45;
      localStorage.setItem(`activity`, activity);
    }
    this.setState({
      activity
    });

    let clock = getClock();
    if (!clock) {
      clock = 0;
      setClock(clock);
    }
    this.setState({
      clock
    });

    let bellStart = getBellStart();
    if (!bellStart) {
      bellStart = 1;
      setBellStart(bellStart);
    }
    this.setState({
      bellStart
    });

    let bellFinish = getBellFinish();
    if (!bellFinish) {
      bellFinish = 1;
      setBellFinish(bellFinish);
    }
    this.setState({
      bellFinish
    });

    let intervals = getIntervals();
    if (!intervals) {
      intervals = '[]';
      setIntervals(intervals);
    }
    this.setState({
      intervals: JSON.parse(intervals)
    });
  };

  onChangeBellSelect = (e) => {
    this.setState({
      [e.target.name]: parseInt(e.target.value)
    });
    if (parseInt(e.target.value) !== 0) {
      this.playBell(e.target.value, 3000);
    }
  };

  onChangeSelect = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value)
    });
  };

  switchActivity = id => async () => {
    const activity = parseInt(id);
    this.setState({
      activity,
      switcherOpen: false,
    });
    localStorage.setItem(`activity`, activity);
    this.setState({
      bellStart: getBellStart(),
      bellFinish: getBellFinish(),
      intervals: JSON.parse(getIntervals()),
      clock: getClock(),
    });
    const user = await getUser();
    if (user && window.navigator.onLine) {
      saveActivity(user, activity);
    }
  };

  openActivitySwitcher = () => {
    this.setState({
      switcherOpen: true,
    });
  };

  startTimer() {
    this.shortPrepareFlag = false;
    setTimeout(() => {
      this.shortPrepareFlag = true;
    }, 1000);
    this.noSleep.enable();
    this.bgTimerHelper.enable();
    this.setState({
      prepareTimerStatus: "running",
      mainTimerStatus: "pending"
    })
  }

  resumeTimer() {
    const {prepareTimer} = this.state;

    this.noSleep.enable();
    this.bgTimerHelper.enable();
    if (prepareTimer !== 0) {
      this.setState({
        prepareTimerStatus: "running",
        mainTimerStatus: "pending"
      });
    } else {
      this.setState({
        prepareTimerStatus: "pending",
        mainTimerStatus: "running"
      });
    }
  }

  pauseTimer() {
    this.noSleep.disable();
    this.bgTimerHelper.disable();
    this.setState({
      prepareTimerStatus: "pending",
      mainTimerStatus: "pending"
    })
  }

  resetTimer() {
    const {clock} = this.state;
    this.setState({
      prepareTimerStatus: "stopped",
      mainTimerStatus: "stopped",
      prepareTimer: timeStringToSeconds(getInitialTimerValue('prepare')),
      mainTimer: clock === 0 ? timeStringToSeconds(getInitialTimerValue('main')) : 0,
    })
  }

  async onMainTimerFinished() {
    const userLoggedIn = await getUser();
    this.playBell(this.state.bellFinish);
    this.noSleep.disable();
    this.bgTimerHelper.disable();
    this.setState({
      prepareTimerStatus: "ended",
      mainTimerStatus: "ended",
      showModal: !userLoggedIn
    });

    if (userLoggedIn && window.navigator.onLine) {
      saveResultsToGoogle();
    } else {
      saveResultsToCache();
    }
  }

  onPrepareTimerFinished() {
    if (this.shortPrepareFlag) {
      this.playBell(this.state.bellStart);
    }
    this.setState({
      prepareTimerStatus: "pending",
      mainTimerStatus: "running"
    })
  }

  onClosePopup() {
    this.setState({
      showModal: false,
      modalMessage: null,
      modalConfirm: null
    });
  }

  onTimeUpdated(string) {
    this.currentTimeString = string;
  }

  async stopAndSaveMeditation() {
    const userLoggedIn = await getUser();
    this.setState({
      prepareTimerStatus: "ended",
      mainTimerStatus: "ended",
      showModal: !userLoggedIn
    });

    if (userLoggedIn && window.navigator.onLine) {
      saveResultsToGoogle(this.currentTimeString);
    } else {
      saveResultsToCache(this.currentTimeString);
    }
  }

  confirmStop() {
    this.setState({
      showModal: true,
      modalMessage: "Reset without saving?",
      modalConfirm: "resetTimer"

    })
  }

  confirmStopAndSync() {
    this.setState({
      showModal: true,
      modalMessage: "Save this session?",
      modalConfirm: "stopAndSaveMeditation"
    })
  }

  openSettings() {
    const {mainTimerStatus, prepareTimerStatus} = this.state;
    if (mainTimerStatus === 'running' || prepareTimerStatus === 'running') {
      this.pauseTimer();
    }
    this.setState({
      showSettings: true,
    });
  }

  closeSettings() {
    const {mainTimerStatus, prepareTimerStatus} = this.state;
    this.setInitialSettings();
    this.setState({
      showSettings: false,
    });
    if (mainTimerStatus === 'pending' && prepareTimerStatus === 'pending') {
      setTimeout(() => {
        this.resumeTimer();
      }, 1000);
    }
  }

  onAddIntervalBell = ({sound, frequency, interval}) => {
    let {intervals} = this.state;
    intervals = intervals.filter(item => item.interval !== interval);
    intervals.push({sound: parseInt(sound), frequency, interval});
    this.setState({intervals});
  };

  removeIntervalBell = i => () => {
    const {intervals} = this.state;
    intervals.splice(i, 1);
    setIntervals(JSON.stringify(intervals));
    this.setState({intervals});
  };

  async applySettings() {
    const {bellStart, bellFinish, intervals, clock, activity} = this.state;
    if (activity === 45) {
      localStorage.setItem(`intervals_meditation`, JSON.stringify(intervals));
      localStorage.setItem(`bellStart_meditation`, bellStart);
      localStorage.setItem(`bellFinish_meditation`, bellFinish);
      localStorage.setItem(`clock_meditation`, clock);
    }
    if (activity === 100) {
      localStorage.setItem(`intervals_yoga`, JSON.stringify(intervals));
      localStorage.setItem(`bellStart_yoga`, bellStart);
      localStorage.setItem(`bellFinish_yoga`, bellFinish);
      localStorage.setItem(`clock_yoga`, clock);
    }

    const user = await getUser();
    if (user && window.navigator.onLine) {
      saveSettings(user, {bellStart, bellFinish, intervals, clock});
    }
    this.setState({
      showSettings: false,
    });
    this.resetTimer();
  }

  setTime(val, type) {
    this.setState({
      [`${type}Timer`]: val
    });
  }

  render() {
    const {
      mainTimerStatus,
      prepareTimerStatus,
      showModal,
      modalMessage,
      modalConfirm,
      showSettings,
      intervals,
      bellStart,
      bellFinish,
      prepareTimer,
      mainTimer,
      activity,
      clock,
      switcherOpen,
    } = this.state;
    const contentPopup = modalMessage ? (
      <div className="popup-content-wrap">
        {modalMessage}
        <div className="control-icons">
          <MaterialIcon
            icon="cancel"
            className="icon"
            onClick={this.onClosePopup}
          />
          <MaterialIcon
            icon="check_circle"
            className="icon"
            onClick={() => {
              this.onClosePopup();
              this[modalConfirm]();
            }}
          />
        </div>
      </div>
    ) : (
      <div className="popup-content-wrap">
        <h2>You are not logged in.</h2>
        <p>This session will be automatically synchronized after the next logged in session.</p>
        <div className="control-icons">
          <MaterialIcon
            icon="check_circle"
            className="icon"
            onClick={this.onClosePopup}
          />
        </div>
      </div>
    );

    return showSettings ?
      <Settings
        intervals={intervals}
        bellStart={bellStart}
        bellFinish={bellFinish}
        onChangeBellSelect={this.onChangeBellSelect}
        onChangeSelect={this.onChangeSelect}
        closeSettings={this.closeSettings}
        applySettings={this.applySettings}
        removeIntervalBell={this.removeIntervalBell}
        onAddIntervalBell={this.onAddIntervalBell}
        clock={clock}
        activity={activity}
      /> :
      <div className="timer-page page">
        <Popup
          open={showModal}
          modal
          overlayStyle={{
            backgroundColor: "transparent"
          }}
          onClose={this.onClosePopup}
        >
          {contentPopup}
        </Popup>
        <div className="timer-digits-container">
          <div className="settings-meditate">
            <div className={'activity-switcher'}>
              <span onClick={this.openActivitySwitcher}>{activity === 45 ? 'Meditation' : 'Yoga'}</span>
              {switcherOpen ? (
                <ul className={'activities'}>
                  {activities.map(({title, id}) => (
                    <li key={id} onClick={this.switchActivity(id)}>
                      <span>{title}</span>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
            <div onClick={this.openSettings} className='settings-icon'>
              <MaterialIcon icon='settings'/>
            </div>
          </div>
          {clock === 0 ? (
            <div>
              <TimerDigits
                className={"main-timer timer-digits"}
                timerType={"main"}
                timerStatus={mainTimerStatus}
                initialTimeString="20:00"
                onFinished={this.onMainTimerFinished}
                onTimeUpdated={this.onTimeUpdated}
                intervals={intervals}
                s={mainTimer}
                setTime={this.setTime}
                playBell={this.playBell}
              />
              <TimerDigits
                className={"prepare-timer timer-digits"}
                timerType={"prepare"}
                title={"PREPARE"}
                timerStatus={prepareTimerStatus}
                initialTimeString="00:10"
                onFinished={this.onPrepareTimerFinished}
                intervals={intervals}
                s={prepareTimer}
                setTime={this.setTime}
                playBell={this.playBell}
              />
            </div>
          ) : (
            <div>
              <StopwatchDigits
                className={"main-timer timer-digits"}
                timerType={"main"}
                timerStatus={mainTimerStatus}
                initialTimeString="00:00"
                onTimeUpdated={this.onTimeUpdated}
                intervals={intervals}
                s={mainTimer}
                setTime={this.setTime}
                playBell={this.playBell}
                clock={clock}
              />
              <TimerDigits
                className={"prepare-timer timer-digits"}
                timerType={"prepare"}
                title={"PREPARE"}
                timerStatus={prepareTimerStatus}
                initialTimeString="00:10"
                onFinished={this.onPrepareTimerFinished}
                intervals={intervals}
                s={prepareTimer}
                setTime={this.setTime}
                playBell={this.playBell}
              />
            </div>
          )}

        </div>
        <div className="timer-controls">
          <MaterialIcon
            className={classNames("timer-controls-icon", "additional-icon", {
              "hide-icon": mainTimerStatus !== "pending" || prepareTimerStatus !== "pending"
            })}
            icon="cloud_upload"
            onClick={this.confirmStopAndSync}
          />
          <MaterialIcon
            className={classNames("timer-controls-icon", {
              "hide-icon": (mainTimerStatus === "running") || (prepareTimerStatus === "running"),
              "paused-animation": mainTimerStatus === "pending" || prepareTimerStatus === "pending"
            })}
            icon="play_circle_outline"
            onClick={this.startTimer}
          />
          <MaterialIcon
            className={classNames("timer-controls-icon", {
              "hide-icon": (mainTimerStatus !== "running") && (prepareTimerStatus !== "running")
            })}
            icon="pause_circle_outline"
            onClick={this.pauseTimer}
          />
          <MaterialIcon
            className={classNames("timer-controls-icon", "additional-icon", {
              "hide-icon": mainTimerStatus === "stopped" || prepareTimerStatus === "stopped" || mainTimerStatus === "ended"
            })}
            icon="stop"
            onClick={this.confirmStop}
          />
        </div>
      </div>
  }
};