import {database} from "./firebase";
import {getUser} from "./auth";
import {formatDate} from "./utils";

export const saveYogaToFirebase = async data => {
  const user = await getUser();
  if (user && user.uid) {
    const key = database.ref().child(`users/${user.uid}/yoga`).push().key;

    const updates = {};
    updates[`users/${user.uid}/yoga/${key}`] = data;

    return database.ref().update(updates);
  }
};

export const getYogas = async (user, groupByDate = false) => {
  if (user && user.uid) {
    return database
      .ref(`users/${user.uid}/yoga`)
      .orderByChild('date')
      .once('value')
      .then(snapshot => {
        let yogas = [];
        snapshot.forEach(yoga => {
          yogas.push({
            date: new Date(yoga.val().date),
            time: yoga.val().value,
          });
        });
        if (groupByDate) {
          const yogasByDate = [];
          const dates = [];
          yogas.forEach(yoga => {
            let index = dates.indexOf(formatDate(yoga.date));
            if (index !== -1) {
              let element = yogasByDate.find(item => formatDate(item.date) === dates[index]);
              yogasByDate[yogasByDate.indexOf(element)].time += yoga.time;
            } else {
              dates.push(formatDate(yoga.date));
              yogasByDate.push(yoga)
            }
          });
          return yogasByDate;
        }
        return yogas;
      });
  } else {
    return [];
  }
};
