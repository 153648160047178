import CryptoJS from 'crypto-js';
import {database} from "./firebase";
import {SECRET_KEY} from './consts';

let count = 0;

export const getNotes = (user, multi = 1) => {
  if (user && user.uid) {
    return database
      .ref(`users/${user.uid}/notes`)
      .orderByChild('date')
      .limitToLast(10*multi)
      .once('value')
      .then(snapshot => {
        let notes = [];
        snapshot.forEach(note => {
          const bytes = CryptoJS.AES.decrypt(note.val().text.toString(), SECRET_KEY);

          notes.push({
            id: note.key,
            text: bytes.toString(CryptoJS.enc.Utf8),
            date: new Date(note.val().date)
          });
        });

        notes.reverse();

        database.ref(`users/${user.uid}/notes`).on('child_added', () => count++);
        database.ref(`users/${user.uid}/notes`).on('child_removed', () => count--);

        return database.ref(`users/${user.uid}/notes`).once("value").then(snap => {
          count = snap.numChildren();

          return {notes, count};
        });
      });
  } else {
    return [];
  }
};

export const saveNote = (user, {id, ...note}) => {
  if (user && user.uid) {
    let key = id || database.ref().child(`users/${user.uid}/notes`).push().key;

    note.text = CryptoJS.AES.encrypt(note.text, SECRET_KEY).toString();

    const updates = {};
    updates[`users/${user.uid}/notes/`+key] = note;

    return database.ref().update(updates);
  }
};

export const deleteNote = (user, id) => user && user.uid && database.ref().child(`users/${user.uid}/notes/`+id).remove();
