import {database} from "./firebase";

export const getSettings = (user) => {
  if (user && user.uid) {
    return database
      .ref(`users/${user.uid}/settings`)
      .once('value')
      .then(snapshot => snapshot.val());
  } else {
    return [];
  }
};

export const saveSettings = (user, {bellStart, bellFinish, intervals, clock}) => {
  const activity = parseInt(localStorage.getItem(`activity`));

  if (user && user.uid) {
    const updates = {};
    if (activity === 45) {
      updates[`users/${user.uid}/settings/meditation/bellStart`] = bellStart;
      updates[`users/${user.uid}/settings/meditation/bellFinish`] = bellFinish;
      updates[`users/${user.uid}/settings/meditation/intervals`] = intervals;
      updates[`users/${user.uid}/settings/meditation/clock`] = clock;
    }
    if (activity === 100) {
      updates[`users/${user.uid}/settings/yoga/bellStart`] = bellStart;
      updates[`users/${user.uid}/settings/yoga/bellFinish`] = bellFinish;
      updates[`users/${user.uid}/settings/yoga/intervals`] = intervals;
      updates[`users/${user.uid}/settings/yoga/clock`] = clock;
    }

    return database.ref().update(updates);
  }
};

export const saveActivity = (user, activity) => {
  if (user && user.uid) {
    const updates = {};
    updates[`users/${user.uid}/settings/activity`] = activity;

    return database.ref().update(updates);
  }
};

export const saveTimerInitValues = (user) => {
  if (user && user.uid) {
    const updates = {};
    updates[`users/${user.uid}/settings/meditation/mainTimerInitValue`] = localStorage.getItem(`main_initial_value_meditation`);
    updates[`users/${user.uid}/settings/meditation/prepareTimerInitValue`] = localStorage.getItem(`prepare_initial_value_meditation`);
    updates[`users/${user.uid}/settings/yoga/mainTimerInitValue`] = localStorage.getItem(`main_initial_value_yoga`);
    updates[`users/${user.uid}/settings/yoga/prepareTimerInitValue`] = localStorage.getItem(`prepare_initial_value_yoga`);

    return database.ref().update(updates);
  }
};
