import React from 'react';

import TimeInput from './TimeInput';
import {secondsToTimeString, timeStringToSeconds} from '../../services/timerEngine';
import {saveTimerInitValues} from "../../services/settings";
import {getUser} from "../../services/auth";
import {getInitialTimerValue, setInitialTimerValue} from "../../services/utils";


export default class TimerDigits extends React.Component {
  constructor() {
    super();

    this.tikTak = this.tikTak.bind(this);
    this.timeChangeHandler = this.timeChangeHandler.bind(this);
    this.resetTickerInterval = this.resetTickerInterval.bind(this);
    this.setInitialTime = this.setInitialTime.bind(this);
    this.clearTickerInterval = this.clearTickerInterval.bind(this);
  }

  componentDidMount() {
    this.setInitialTime();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.timerStatus !== this.props.timerStatus) {
      this.tikTak();
    }
  }

  componentWillUnmount() {
    this.clearTickerInterval();
  }

  tikTak(forcedValue) {
    const {onFinished, timerStatus, setTime, timerType} = this.props;
    let s = forcedValue || this.props.s;

    this.clearTickerInterval();
    switch (timerStatus) {
      case "ended":
        this.resetTickerInterval(3000);
        return;
      case "stopped":
        this.resetTickerInterval();
        break;
      case "pending":
        break;
      case "running":
        if ((s - 1) <= 0) {
          setTime(0, timerType);
          onFinished && onFinished();
        } else {
          setTime((s - 1), timerType);
          this.checkIntervalBell();
          this.intervalId = setTimeout(() => {
            this.tikTak();
          }, 1000);
        }
        break;
      default:
        break;
    }
  }

  setInitialTime() {
    const {timerType, initialTimeString, setTime, s, timerStatus} = this.props;
    const cachedTimeString = getInitialTimerValue(timerType);

    if (cachedTimeString) {
      if (!s && (timerStatus !== 'pending' || timerType !== 'prepare')) {
        setTime(timeStringToSeconds(cachedTimeString), timerType);
      }
    } else {
      setInitialTimerValue(timerType, initialTimeString);
      if (!s && (timerStatus !== 'pending' || timerType !== 'prepare')) {
        setTime(timeStringToSeconds(initialTimeString), timerType);
      }
    }
  }

  resetTickerInterval(delay) {
    setTimeout(() => {
      this.setInitialTime();
    }, delay || 0);
  }

  clearTickerInterval() {
    clearTimeout(this.intervalId);
    this.intervalId = null;
  }

  async timeChangeHandler(value) {
    const {timerType, timerStatus, setTime} = this.props;
    if (timerStatus === "stopped") {
      setInitialTimerValue(timerType, value);
      const user = await getUser();
      if (user) {
        saveTimerInitValues(user);
      }

      setTime(timeStringToSeconds(value), timerType)
    }
  }

  checkIntervalBell() {
    const {intervals, timerType, s, playBell} = this.props;
    const timeStart = getInitialTimerValue(timerType);
    const timeGone = secondsToTimeString(timeStringToSeconds(timeStart) - s);
    if (timerType !== 'main' || timeGone === '00:00') {
      return;
    }
    intervals.forEach(interval => {
      if (interval.frequency === 'Once' && timeGone === interval.interval) {
        playBell(interval.sound);
      }
      if (interval.frequency === 'Every' && (s % timeStringToSeconds(interval.interval)) === 0) {
        playBell(interval.sound);
      }
    });
  }

  render() {
    const {timerType, timerStatus, initialTimeString, title, className, onTimeUpdated, s} = this.props;
    const time = secondsToTimeString(s);

    onTimeUpdated && onTimeUpdated(time);
    if (timerStatus === "stopped") {
      const cachedTimeString = getInitialTimerValue(timerType);
      const timeString = cachedTimeString || initialTimeString;
      return (
        <div className={className}>
          <div className="timer-title">
            {title}
          </div>
          <TimeInput
            initTime={timeString}
            className="digits"
            onTimeChange={this.timeChangeHandler}
          />
        </div>
      )
    } else {
      return (
        <div className={className}>
          <div className="timer-title">
            {title}
          </div>
          <div className="digits digits-show">
            {time}
          </div>
        </div>
      )
    }
  }
}