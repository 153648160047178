import XLSX from 'xlsx';
import {getNotes} from "./journal";
import {getMeditations} from "./meditation";
import {formatDate, ms2m} from "./utils";
import {getYogas} from "./yoga";
import {database} from "./firebase";

export const exportToExcel = (user) => async () => {

  const fitness = await getMeditations(user, true);

  const yogas = await getYogas(user, true);

  const fitnessData = fitness.filter(meditation => {
    return meditation.time !== 0
  }).map(meditation => {
    return {
      date: formatDate(meditation.date),
      entry: null,
      yoga: null,
      time: ms2m(meditation.time).toFixed(2),
      delete: false,
    }
  });

  const yogasData = yogas.filter(yoga => {
    return yoga.time !== 0
  }).map(yoga => {
    return {
      date: formatDate(yoga.date),
      entry: null,
      yoga: ms2m(yoga.time).toFixed(2),
      time: null,
      delete: false,
    }
  });

  const notes = await getNotes(user, 999);
  const notesData = notes.notes.map(note => {
    const time = fitnessData.find(item => item.date === formatDate(note.date));
    if (time) {
      let index = fitnessData.indexOf(time);
      fitnessData[index].delete = true;
    }

    const yogaTime = yogasData.find(item => item.date === formatDate(note.date));
    if (yogaTime) {
      let indexYoga = yogasData.indexOf(yogaTime);
      yogasData[indexYoga].delete = true;
    }
    return {
      date: formatDate(note.date),
      entry: note.text,
      yoga: yogaTime ? yogaTime.yoga : null,
      time: time ? time.time : null,
    }
  });

  fitnessData.forEach(item => {
    if (item.delete) {
      let index = fitnessData.indexOf(item);
      fitnessData.splice(index, 1);
    }
  });

  yogasData.forEach(item => {
    if (item.delete) {
      let index = yogasData.indexOf(item);
      yogasData.splice(index, 1);
    }
  });

  const data = [...notesData, ...fitnessData, ...yogasData];
  data.sort(compare);

  const worksheetData = [
    ["Date", "Entry", "Total minutes meditated", "Total minutes yoga"],
    ...data.map(item => [item.date, item.entry, item.time, item.yoga])
  ];

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, 'my_peace_pal_export.xlsx');

};

const compare = (a, b) => {
  if (a.date < b.date)
    return -1;
  if (a.date > b.date)
    return 1;
  return 0;
};

export const createDownloadLink = user => {
  if (user && user.uid) {
    const updates = {};
    const date = new Date();
    date.setDate(date.getDate() + 2);
    updates[`users/${user.uid}/downloadLink`] = date;

    return database.ref().update(updates);
  } else {
    return new Promise(resolve => resolve());
  }
};

export const getDownloadLink = user => {
  if (user && user.uid) {
    return database
      .ref(`users/${user.uid}/downloadLink`)
      .once('value')
      .then(snapshot => snapshot.val());
  } else {
    return new Promise(resolve => resolve());
  }
};
