export const GTM_ID = "GTM-53MN6JS";
export const GOOGLE_FIT_DATASOURCE = {
  "dataStreamName": "mypeacepal",
  "type": "derived",
  "name": "My Peace Pal",
  "application": {
    "detailsUrl": "https://app.mypeacepal.com",
    "name": "My Peace Pal",
    "version": "1"
  },
  "dataType": {
    "field": [
      {
        "name": "activity",
        "format": "integer"
      }
    ],
    "name": "com.google.activity.segment"
  },
  "device": {
    "manufacturer": "Unknown",
    "model": "Unknown",
    "type": "tablet",
    "uid": "1000001",
    "version": "1"
  }
};
export const LOCALSTORAGE_FIELD_NAME = "google_user_data"
export const userKeys = [
  "email",
  "gender",
  "displayName",
  "emailVerified",
  "metadata",
  "address",
  "birthday",
  "description",
  "phoneNumber",
  "photoURL",
  "credential"
];
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
export const journalPageStates = {
  LIST: "list",
  CREATE: "create",
  EDIT: "edit",
  VIEW: "view"
};
export const SECRET_KEY = 'JOURNAL_$$_SECRET_##_KEY';
export const sounds = [
  {
    title: 'No sound',
    url: '',
  }, {
    title: 'Zen',
    url: '/assets/sounds/Zen.mp3',
  }, {
    title: 'Arhat',
    url: '/assets/sounds/Arhat.mp3',
  }, {
    title: 'Koshi chimes',
    url: '/assets/sounds/Koshi_chimes.mp3',
  }, {
    title: 'Sakya',
    url: '/assets/sounds/Sakya.mp3',
  }, {
    title: 'Tyngsha cymbals',
    url: '/assets/sounds/Tyngsha_cymbals.mp3',
  },
];
export const tariff = {
  annual: 39.84,
  perDownload: 1.99,
};
export const paypal = {
  id: 'Adinx688qXz0rjVPVI3XGbvYOQSrx0XPG7mxb49GZ0naPI3rfGQZGTHIqoL4zfWomZltBcbTXiib7W-u',
  sandboxId: 'AR-KxI8WYsTpdiFwD1Ip17PFievHy1tWTzUWi-fHb5uCh5RSTt-jyaprCcD_GpOObM2rttynCUA05QFa',
  env: 'production', // production/sandbox
  currency: 'USD',
};
export const activities = [
  {
    title: 'Meditation',
    id: 45,
  }, {
    title: 'Yoga',
    id: 100,
  }
];
export const clocks = [
  'Timer',
  'Stopwatch',
];