import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "./services/routerHistory";

import MainPage from "./containers/MainPage";
import AboutPage from "./containers/AboutPage";
import FAQPage from "./containers/FAQPage";
import FeedbackPage from "./containers/FeedbackPage";
import SupportPage from "./containers/SupportPage";
import ProfilePage from "./containers/ProfilePage";

export default props => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/"
          component={MainPage}
        />
        <Route
          exact
          path="/about"
          component={AboutPage}
        />
        <Route
          exact
          path="/faq"
          component={FAQPage}
        />
        <Route
          exact
          path="/feedback"
          component={FeedbackPage}
        />
        <Route
          exact
          path="/support"
          component={SupportPage}
        />
        <Route
          exact
          path="/profile"
          component={ProfilePage}
        />
      </Switch>
    </Router>
  )
}