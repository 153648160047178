import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import config from '../config/firebase';

if (!firebase.apps.length) {
  firebase.initializeApp(config);
};

const _auth = firebase.auth;
const _database = firebase.database;
const auth = _auth();
const database = _database();

export {
  _auth,
  _database,
  auth,
  database
}