import React from 'react';
import {secondsToTimeString, timeStringToSeconds} from '../../services/timerEngine';

export default class StopwatchDigits extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.timerStatus !== this.props.timerStatus) {
      this.tikTak();
    }
  }

  componentDidMount() {
    this.setInitialTime();
  }

  componentWillUnmount() {
    this.clearTickerInterval();
  }

  setInitialTime() {
    const {timerType, initialTimeString, setTime, timerStatus} = this.props;
    if (timerStatus !== 'pending') {
      setTime(timeStringToSeconds(initialTimeString), timerType);
    }

  }

  tikTak(forcedValue) {
    const {timerStatus, setTime, timerType} = this.props;
    let s = forcedValue || this.props.s;

    this.clearTickerInterval();
    switch (timerStatus) {
      case "ended":
        this.resetTickerInterval(3000);
        return;
      case "stopped":
        this.resetTickerInterval();
        break;
      case "pending":
        break;
      case "running":
        setTime((s + 1), timerType);
        this.checkIntervalBell();
        this.intervalId = setTimeout(() => {
          this.tikTak();
        }, 1000);
        break;
      default:
        break;
    }
  }

  resetTickerInterval(delay) {
    setTimeout(() => {
      this.setInitialTime();
    }, delay || 0);
  }

  clearTickerInterval() {
    clearTimeout(this.intervalId);
    this.intervalId = null;
  }

  async timeChangeHandler(value) {
    const {timerType, timerStatus, setTime} = this.props;
    if (timerStatus === "stopped") {
      setTime(timeStringToSeconds(value), timerType)
    }
  }

  checkIntervalBell() {
    const {intervals, timerType, s, playBell} = this.props;
    const timeGone = secondsToTimeString(s);
    if (timerType !== 'main' || timeGone === '00:00') {
      return;
    }
    intervals.forEach(interval => {
      if (interval.frequency === 'Once' && timeGone === interval.interval) {
        playBell(interval.sound);
      }
      if (interval.frequency === 'Every' && (s % timeStringToSeconds(interval.interval)) === 0) {
        playBell(interval.sound);
      }
    });
  }

  render() {
    const {title, className, s, onTimeUpdated} = this.props;
    const time = secondsToTimeString(s);
    onTimeUpdated && onTimeUpdated(time);
    return (
      <div className={className}>
        <div className="timer-title">
          {title}
        </div>
        <div className="digits digits-show">
          {time}
        </div>
      </div>
    )
  }
}