import React from 'react';
import classNames from 'classnames';

import Chart from "../components/Chart/Chart";
import Auth from "../components/Auth";
import {getFitnesDataSources, postFitnesDataSource, getFitnesDataSet} from "../services/api";
import {periodString, fitDataToD3, parseJSON} from "../services/utils";
import {getUser} from "../services/auth";
import {GOOGLE_FIT_DATASOURCE} from "../services/consts";

const periods = [
  {
    name: "Week",
    value: 7,
    format: "%a"
  },
  {
    name: "Month",
    value: 31,
    format: "%d"
  },
  {
    name: "Year",
    value: 365,
    format: "%b"
  }
];

export default class ChartPage extends React.Component {
  constructor() {
    super();

    this.state = {
      userData: null,
      errorData: null,
      fitnessData: [],
      maxValue: 60,
      isLoading: false,
      period: 7,
      tickFormat: "%a"
    };

    this.authError = this.authError.bind(this);
    this.fetchFitData = this.fetchFitData.bind(this);
  }

  async componentDidMount() {
    const user = await getUser();
    if (user) {
      this.fetchFitData();
      this.setState({
        userData: user
      })
    }
  }

  authError(message) {
    const parsed = parseJSON(message);
    this.setState({
      errorData: (parsed && parsed.error && parsed.error.message) || "Auth error"
    })
  }

  fetchFitData(conf) {
    const period = (conf && conf.value) || this.state.period;
    return getFitnesDataSources()
      .then(data => data.dataSource.find(el => el.dataStreamName === GOOGLE_FIT_DATASOURCE.dataStreamName))
      .then(dataSource => dataSource ? dataSource : postFitnesDataSource(JSON.stringify(GOOGLE_FIT_DATASOURCE)))
      .then(myPeacePalDataSource => {
        localStorage.setItem("data_stream_id", myPeacePalDataSource.dataStreamId);
        return getFitnesDataSet(myPeacePalDataSource.dataStreamId, periodString(Date.now() - period * 24 * 60 * 60 * 1000))
      })
      .then(dataSet => {
        const {data, maxValue} = fitDataToD3(dataSet, period);
        this.setState({
          fitnessData: data,
          maxValue: maxValue,
          isLoading: false,
          period: period,
          tickFormat: (conf && conf.format) || "%a"
        })
      })
  }

  onChangePeriod(newPeriod) {
    if (this.state.userData) {
      this.fetchFitData(newPeriod);
    } else {
      window.location.reload();
    }
  }

  render() {
    const {userData, errorData, fitnessData, isLoading, maxValue, period, tickFormat} = this.state;
    return (
      <div className="chart-page page">
        <div className={classNames("period-select-container", {
          "hidden": !userData
        })}>
          {periods.map(p => (
            <span
              key={p.value}
              className={classNames({
                selected: period === p.value
              })}
              onClick={() => this.onChangePeriod({
                value: p.value,
                format: p.format
              })}
            >
              {p.name}
            </span>
          ))}
        </div>
        <div className="chart-container">
          <div className={classNames("login-overlay", "flex-center", {
            "hidden": userData
          })}>
            <Auth
              onError={this.authError}
            />
            <div className="login-error-block">
              {errorData}
            </div>
          </div>
          <Chart
            isLoading={isLoading}
            userData={userData}
            maxValue={maxValue}
            data={fitnessData}
            period={period}
            tickFormat={tickFormat}
          />
        </div>
      </div>
    )
  }
};