import React from 'react';
import classNames from 'classnames';

export default class Card extends React.Component {
  constructor() {
    super();

    this.state = { };
  }

  render() {
    const {children, header, scrollRef, noScroll} = this.props;

    return (
      <div className="card">
        <div className="card-header">
          {header}
        </div>
        <div className={classNames("card-body", {"no-scroll": noScroll})} ref={scrollRef || null}>
          {children}
        </div>
      </div>
    )
  }
}
